import React, { useContext } from "react";
import { navigate } from "gatsby";
import { FirebaseContext } from "../Firebase";

import PropTypes from "prop-types";
import styled from "styled-components";
import { Link } from "gatsby-theme-material-ui";
import { OutboundLink } from "gatsby-plugin-google-gtag";
import { Box, Button, Container, Grid, Typography } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import CssBaseline from "@material-ui/core/CssBaseline";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import Slide from "@material-ui/core/Slide";
import OpenInNewRoundedIcon from "@material-ui/icons/OpenInNewRounded";

import { Helmet } from "react-helmet";
import SEO from "../seo";

import "./header.scss";

function HideOnScroll(props) {
  const { children, window } = props;
  const trigger = useScrollTrigger();

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

const Header = ({ siteTitle }) => {
  const { firebase, user } = useContext(FirebaseContext);
  function handleLogoutClick() {
    firebase.logout().then(() => navigate("/login"));
  }

  return (
    // Logic for user auth removed and saved to Notion to bring back later.

    <React.Fragment>
      <CssBaseline />
      <HideOnScroll>
        <AppBar className="header-main" color="default" elevation={0}>
          <Toolbar disableGutters>
            <Container maxWidth="sm">
              <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="center"
              >
                <Grid item xs={4}>
                  <Box className="brand-home">
                    <Link color="secondary" to="/">
                      {siteTitle}
                    </Link>
                  </Box>
                </Grid>
                <Grid item xs={8}>
                  <Box className="header-links">
                    <OutboundLink
                      className="outbound-link-outline"
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-disabled="false"
                      variant="outlined"
                      href="https://airtable.com/shrh5vzSsiFq5381a"
                    >
                      <Box component="span">Add your Journey</Box>
                      <Box component="span" className="icon">
                        <OpenInNewRoundedIcon />
                      </Box>
                    </OutboundLink>
                  </Box>
                </Grid>
              </Grid>
            </Container>
          </Toolbar>
        </AppBar>
      </HideOnScroll>
      <Toolbar />
    </React.Fragment>
  );
};

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
