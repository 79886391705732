const firebaseConfig ={
    apiKey: "AIzaSyBsBTx3lZJvtyAGQW34o0PvR92eBWaHOBw",
    authDomain: "gatsby-book-club-c89f2.firebaseapp.com",
    databaseURL: "https://gatsby-book-club-c89f2.firebaseio.com",
    projectId: "gatsby-book-club-c89f2",
    storageBucket: "gatsby-book-club-c89f2.appspot.com",
    messagingSenderId: "1059213789436",
    appId: "1:1059213789436:web:c5fb7076c464ed64f9ef79"
}

export default firebaseConfig;
