// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-app-index-js": () => import("./../../../src/pages/app/index.js" /* webpackChunkName: "component---src-pages-app-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-register-js": () => import("./../../../src/pages/register.js" /* webpackChunkName: "component---src-pages-register-js" */),
  "component---src-pages-testpage-js": () => import("./../../../src/pages/testpage.js" /* webpackChunkName: "component---src-pages-testpage-js" */),
  "component---src-pages-trip-js": () => import("./../../../src/pages/trip.js" /* webpackChunkName: "component---src-pages-trip-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-templates-trip-template-js": () => import("./../../../src/templates/tripTemplate.js" /* webpackChunkName: "component---src-templates-trip-template-js" */),
  "component---src-templates-user-profile-js": () => import("./../../../src/templates/userProfile.js" /* webpackChunkName: "component---src-templates-user-profile-js" */)
}

