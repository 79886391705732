import React from "react";
import { Container, Grid, Link, Typography } from "@material-ui/core";

import "./footer.scss";

const Footer = () => (
  <footer className="footer-main">
    <Container maxWidth="sm">
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="center"
      >
        <Grid item xs>
          <Typography variant="caption">
            © {new Date().getFullYear()}, crafted in LA ❤️
          </Typography>
        </Grid>
        <Grid item xs className="text-right">
          <Typography variant="caption">
            <Link href="mailto:feedback@unpackables.com" color="inherit">
              Feedback
            </Link>
          </Typography>
        </Grid>
      </Grid>
    </Container>
  </footer>
);

export default Footer;
