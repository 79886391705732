// src/gatsby-theme-material-ui-top-layout/theme.js

import { createMuiTheme } from "@material-ui/core";

const theme = createMuiTheme({
  breakpoints: {
    values: {
      sm: 600,
      md: 840,
    },
  },
  palette: {
    primary: {
      main: "#3455DB",
    },
    secondary: {
      main: "#1F2229",
    },
  },

  shape: {
    borderRadius: "0.5rem",
  },

  text: {
    primary: "#1F2229",
  },
  typography: {
    fontFamily: ["Inter", "sans-serif"].join(","),
    h1: {
      fontSize: 24,
      fontWeight: 300,
    },
  },
});

export default theme;
